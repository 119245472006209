import axios from 'axios';

function getServerNameFromCookie() {
    const app = document.querySelector('#app');
    if (app && app.__vue__) {
        return app.__vue__.$cookies.get('server-name');
    }
    return null;
}

// 기본 baseURL 설정
const serverName = getServerNameFromCookie();
const baseURL = serverName;

const axiosURL = axios.create({
    baseURL: baseURL
});

// baseURL을 동적으로 변경하는 함수 추가
export function setBaseURL(newBaseURL) {
    axiosURL.defaults.baseURL = newBaseURL;
}

export default axiosURL;
